<template>
    <div id="full-container">
        <h1 id="top-text-2">
            <input type="text" v-model="localPlanTypeString" class="input-as-text" :style="{ width: (localPlanTypeString.length) + 'ch' }"/>Plans
        </h1>
        <div id="window-2">
            <div id="top-row">
                <h2 class="top-description">Adjust the included plan benefit values to determine appropriate pricing.</h2>
            </div>
            <div class="slider-rows">
                <SliderRow v-for="(benefit, index) in benefits" :index="index" :benefit="benefit" :key="`benefit-${benefit.id}-${planType}`" :planType="planType" :inputPercent="percentToSetTo" @benefitPriceUpdated="updatePlanPrices" @edit-benefit="ShowEditBenefit"/>
            </div>
            <div class="btn-container">
                <button class="add-btn hover-grow" @click="addBenefitClick">+ Add Benefit</button>
                <button class="add-btn hover-grow" @click="ShowEditPlan">Edit Plan Details</button>
            </div>
            <div class="bottom-row">
                <!-- <div class="fees-tab">
                    <div class="breakage"> -->
                            
                        <!-- <div class="left-row" style="margin-bottom: 0em;">
                            <span class="breakage-text">Breakage Assumption:</span>
                            <div class="breakage-2">
                                <div class="plus-minus-buttons">
                                    <PlusMinusButton class="plus-minus" :char="'+'" @plusMinusClick="UpdatePercentBreakage"/>
                                    <PlusMinusButton class="plus-minus" :char="'-'" @plusMinusClick="UpdatePercentBreakage"/>
                                </div>
                                <div class="breakage-box no-select">{{ percentBreakage }}<span style="font-size: 0.65em;">%</span></div>
                            </div>
                        </div>
                        <div class="left-row">
                            <span class="breakage-text">Include Dental Menu Fee:</span>
                            <div class="checkbox-container grow-medium">
                                <div class="checkbox" @click="toggleDMFeeChecked($event)">
                                    <div class="checkbox-fill" v-if="includeDMFee">
                                        <p class="checkbox-text">✓</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-row">
                            <span class="breakage-text">Include Processing Fee:</span>
                            <div class="checkbox-container grow-medium">
                                <div class="checkbox" @click="toggleProcessingFeeChecked($event)">
                                    <div class="checkbox-fill" v-if="includeProcessingFee">
                                        <p class="checkbox-text">✓</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="plans-tab">
                    <div class="plan-description">
                        <h3>Choose how often patients can come in</h3>
                    </div>
                    <div class="plans">
                        <Plan v-for="plan in plans" :key="plan.id" :plan="plan" :mode="mode" @UpdatePriceFromPlan="updatePlanPrices"/>
                    </div>
                  </div>
            </div>
        </div>
        <SaveChangesButton v-if="this.mode === 'view'" @btnPressed="GoToOverview()"></SaveChangesButton>
        <div class="modal-overlay" v-if="showNotes" @click.self="RemoveNotes">
            <NotesModal @click.stop @exitModal="RemoveNotes">
                
            </NotesModal>
        </div>
        <div class="modal-overlay" v-if="showEditBenefit" @click.self="RemoveEditBenefit">
            <EditBenefitDetailsModal @click.stop @exitModal="RemoveEditBenefit" :propBenefit=this.benefitToEdit :planType=this.planType @recalculatePrices="updatePlanPrices">
                
            </EditBenefitDetailsModal>
        </div>
        <div class="modal-overlay" v-if="showModal" @click.self="removeModal">
            <AddBenefitModal @click.stop @exitModal="removeModal" :planType="planType">
                
            </AddBenefitModal>
        </div>
        <!-- Plan settings modal -->
        <div class="modal-overlay" v-if="showEditPlan" @click.self="RemoveEditPlan">
            <div class="full-edit-plan-modal">
                <div class="btn-exit grow-medium" @click="RemoveEditPlan"><i class="pi pi-times"></i></div>
                <h1 class="top-text">{{localPlanTypeString}} Plan Details</h1>
                <div class="left-row" style="margin-bottom: 0em;">
                    <span class="breakage-text">Breakage Discount: &nbsp;
                        <div class="info-icon" @mouseover="showBreakageTooltip = true" @mouseleave="showBreakageTooltip = false">
                            <i class="pi pi-question-circle"></i>
                            <transition name="fade">
                                <div v-if="showBreakageTooltip" class="tooltip">
                                    <!-- Breakage is extra revenue from members plan payments in excess of our budget calculations due to delayed services. This can create funds that can be used for rewards, additional services or profitability for your plan. Alternatively by increasing this % you can discount plan prices making them more affordable but still maintaining the ability to reimburse yourself the determined fees. Average breakage is 15% -->
                                    Breakage is extra revenue from members who don't come in right when they're eligible for their benefits. This can create funds that can be used for rewards, additional services or profitability for your plan. Alternately, a higher breakage assumption allows you to lower a member's monthly cost while reimbursing yourself the same amount for treatments. Average breakage is 15%.
                                </div>
                            </transition>
                        </div> 
                    </span>
                    <div class="checkbox-container">
                        <div class="plus-minus-buttons">
                            <PlusMinusButton class="plus-minus" :char="'+'" @plusMinusClick="UpdatePercentBreakage"/>
                            <PlusMinusButton class="plus-minus" :char="'-'" @plusMinusClick="UpdatePercentBreakage"/>
                        </div>
                        <div class="breakage-box no-select">{{ percentBreakage }}<span style="font-size: 0.65em;">%</span></div>
                    </div>
                </div>
                <div class="left-row">
                    <span class="breakage-text">Include Dental Menu Fee:</span>
                    <div class="checkbox-container grow-medium">
                        <div class="checkbox" @click="toggleDMFeeChecked($event)">
                            <div class="checkbox-fill" v-if="includeDMFee">
                                <p class="checkbox-text">✓</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="left-row">
                    <span class="breakage-text">Include Processing Fee:</span>
                    <div class="checkbox-container grow-medium">
                        <div class="checkbox" @click="toggleProcessingFeeChecked($event)">
                            <div class="checkbox-fill" v-if="includeProcessingFee">
                                <p class="checkbox-text">✓</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="left-row">
                    <span class="breakage-text">Set All Benefits to:</span>
                    <div class="checkbox-container">
                        <input class="percent-input hover-grow" v-model="percentToSetTo" @input="validatePrice($event.target.value)"/><span class="percent-sign">% UCR</span>
                    </div>
                </div>
                <div class="left-row">
                    <span class="breakage-text">Total Plan Discount:</span>
                    <div class="checkbox-container">
                        <div class="percent-input" style="border: 0px; margin-right: 0;">{{ percentDiscount }}</div><span class="percent-sign">% off UCR</span>
                    </div>
                </div>
                <button class="add-btn btn-small hover-grow" @click="ShowNotes">
                    Add Notes
                </button>
            </div>
        </div>
        
    </div>
</template>

<script>
import SliderRow from './SliderRow.vue'
import Plan from './Plan.vue'
import PlusMinusButton from './PlusMinusButton.vue';
import AddBenefitModal from './modals/AddBenefitModal.vue';
import NotesModal from './modals/NotesModal.vue';
import EditBenefitDetailsModal from './modals/EditBenefitDetailsModal.vue'
import SaveChangesButton from './SaveChangesButton.vue';
import { GoToComponent, CalculateComputedPlanPrice, InputToInteger } from '../utils/functions';
import { mapActions } from 'vuex';
export default {
    components: {
        SliderRow,
        Plan,
        PlusMinusButton,
        AddBenefitModal,
        SaveChangesButton,
        NotesModal,
        EditBenefitDetailsModal,
    },
    props: {
        planType: Number,
        mode: String,
    },
    data() {
        return {
            showModal: false,
            showNotes: false,
            showEditBenefit: false,
            showEditPlan: false,
            benefitToEdit: null,
            localPlanType: "",
            computedPlanStringLength: 0,
            localPlanTypeString: "",
            percentToSetTo: 0,
            showBreakageTooltip: false,
        }
    },
    methods: {
        ...mapActions(['updatePlan',
        "updatePercentBreakageStore",
        "toggleIncludeDMFee",
        "toggleIncludeProcessingFee",
        "setPlanTypeName",
        "getPlanTypeFromInt"]),
        updatePlanPrices() {
            this.plans.forEach((plan) => {
                if(plan.monthlyPriceAltered) {
                    return;
                }
                plan.price = CalculateComputedPlanPrice(this.$store, plan);
                if(!plan.monthlyPriceAltered && !plan.annualPriceAltered) {
                    plan.annualPrice = plan.price * 12
                }
                this.updatePlan(plan);
            })
        },
        addBenefitClick() {
            this.showModal = true;
        },
        removeModal() {
            this.showModal = false;
        },
        ShowNotes() {
            this.showNotes = true;
            this.showEditPlan = false;
        },
        RemoveNotes() {
            this.showNotes = false;
        },
        ShowEditBenefit(benefit) {
            this.benefitToEdit = benefit;
            this.showEditBenefit = true;
        },
        RemoveEditBenefit() {
            this.showEditBenefit = false;
        },
        ShowEditPlan() {
            this.showEditPlan = true;
        },
        RemoveEditPlan() {
            this.showEditPlan = false;
        },
        UpdatePercentBreakage(value) {
            if((value === -1 && this.percentBreakage === 0) || (value === 1 && this.percentBreakage === 100)) {
                return
            }
            else {
                let tempBreakage = {"percent": this.percentBreakage + (value * 5), "type": this.planType}
                this.updatePercentBreakageStore(tempBreakage)
                this.updatePlanPrices();
            }
        },
        GoToOverview() {
            GoToComponent(this, 9);
        },
        toggleDMFeeChecked() {
            this.toggleIncludeDMFee();
            this.updatePlanPrices();
        },
        toggleProcessingFeeChecked() {
            this.toggleIncludeProcessingFee();
            this.updatePlanPrices();
        },
        validatePrice(val) {
            let result = InputToInteger(val);
            if(result < 101) {
                this.percentToSetTo = result;
            }
            else {
                this.percentToSetTo = InputToInteger(val.slice(0, -1));
            }
        },
        ExitModal() {
            return
        },
    },
    computed: {
        plans() {
            let plans = this.$store.getters.plansByType(this.planType);
            return plans;
        },
        benefits() {
            let benefits = this.$store.getters.benefitsByType(this.planType);
            return benefits
        },
        percentBreakage() {
            return this.$store.state.percentBreakage[this.planType];
        },
        includeDMFee() {
            return this.$store.state.includeDMFee;
        },
        includeProcessingFee() {
            return this.$store.state.includeProcessingFee;
        },
        thisPlanType() {
            return this.$store.getters.getPlanTypeByIndex(this.planType)
        },
        planTypeString() {
            return this.$store.getters.getPlanTypeFromInt(this.planType);
        },
        percentDiscount() {
            let plan = this.plans[0];
            let planPrice = plan.annualPrice
            let sum = 0
            this.$store.state.benefits.forEach((benefit) => {
                if (benefit.planTypes.includes(this.planType)) {
                    let benefitFrequency = benefit.frequencies[this.planType] === -1 ? plan.frequency : benefit.frequencies[this.planType]
                    sum += benefit.UCR * (12 / benefitFrequency);
                }
                });
            return 100 - Math.ceil((planPrice / sum) * 100);
        }
    },
    mounted() {
        if(this.mode !== 'view') {
            this.updatePlanPrices();
        }
        this.localPlanType = this.planType
        this.localPlanTypeString = this.planTypeString;
    },
    watch: {
        benefits(newValue, oldValue) {
            if(newValue.length != oldValue.length) {
                this.updatePlanPrices();
            }
        },
        planType(newVal, oldVal) {
            this.updatePlanPrices();
            this.localPlanTypeString = this.planTypeString;
            this.showEditPlan = false;
        },
        localPlanTypeString(newVal) {
            this.setPlanTypeName({ id: this.planType, name: newVal });
        }
    }
  };
</script>

<style scoped>

#top-text-2 {
    margin: 0;
    margin-top: 1%;
    margin-bottom: 1%;
}

.input-as-text {
  background: none;
  border: 2px solid transparent;
  border-radius: 10px;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  width: auto;
  text-align: center;
  font-size: 1.05em;
}

.input-as-text:hover {
    border: 2px solid var(--color-bright-blue)
}

#full-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

#window-2 {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 20px;
}

#top-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#top-row h2 {
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1.3em;
}


h1 {
    margin: 0px;
    color: var(--font-color-teal)
}

.slider-rows {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.btn-container {
    margin-top: 0.5em;
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin-bottom: 0.5em;
    align-items: space-between;
}

.add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--color-light-blue);
    border-radius: 40px;
    height: 4em;
    width: 40%;
    background-color: white;
    color: var(--color-bright-blue);
    font-size: 1.5em;
    font-weight: 600;
    padding-right: 0.7em;
    padding-left: 0.7em;
    cursor: pointer;
    max-height: 55px;
    margin-top: 0;
    margin-bottom: 0;
}

.btn-small {
    font-size: 1.15em;
    height: 2.5em;
    width: 30%;
}

.add-btn:active {
    border: 3px solid var(--color-bright-blue)
}

.add-btn:focus {
    outline: none;
}

.side-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.percent-input {
    font-size: 1.7em;
    font-weight: 900;
    color: var(--color-bright-blue);
    border: 2px solid var(--color-light-blue);
    border-radius: 10px;
    margin-right: 0.35em;
    padding: 0.1em;
    max-width: 50px;
    text-align: center;
}

.percent-sign {
    font-size: 1.15em;
    color: var(--color-bright-blue);
    font-weight: 900;
}

.bottom-row {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.fees-tab {
    flex: 1;
    border-right: 1px solid var(--color-medium-gray);
    padding-left: 3%;
    padding-right: 3%;
}

#fees-text {
    margin: 0;
    font-size: 1.5em;
    color: var(--color-dark-gray)
}

.breakage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.5em;
}

.left-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.7em;
}

.breakage-text {
    display: flex;
    flex: 3;
    font-size: 1.2em;
}

.info-icon {
    color: var(--color-medium-gray);
    padding-top: 2px;
    position: relative;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}

.fade-enter, .fade-leave-to {
    /* Initial state for enter, and final state for leave */
    opacity: 0;
}

.tooltip {
    position: absolute;
    top: -165px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #333;
    padding: 5px 10px;
    border-radius: 8px;
    border: 2px solid var(--color-light-blue);
    font-size: 0.75em;
    z-index: 9;
    text-wrap: wrap;
    width: 2000%;
}

.breakage-2 {
    display: flex;
    align-items: center;
    margin-left: 2em;
}

.checkbox-container {
    position:relative;
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
}

.checkbox {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.checkbox-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    font-size: 1.5em;
    left: 2px;
    top: -3px;
}

.breakage-box {
    font-size: 1.7em;
    color: var(--color-bright-blue);
    background-color: white;
    border: 2px solid var(--color-light-blue);
    border-radius: 10px;
    margin: 0.5em;
    margin-right: 0.35em;
    padding: 0.1em;
    min-width: 45px;
    text-align: right;
    font-weight: bold;
}

.line {
    width: 100%; /* If you still want the line to be full width */
    border-top: 1px solid var(--color-medium-gray);
    display: flex;
    margin: 0;
  }

.fees {
    display: flex;
    flex-direction: column;
}

.fee {
    margin-top: 0.4em;
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
}

.fee-price {
    font-weight: bold;
    padding-right: 0.5em;
}

.plans-tab {
    flex: 2.5;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 1em;
    margin-right: 1em;
}

.plans {
    display: flex;
    width: 100%;
    justify-content: center;
}

.plan-description h3 {
    color: var(--color-dark-gray);
    display: flex;
    justify-content: center;
    margin: 1em;
    font-size: 1.3em;
}

.full-edit-plan-modal {
    position: relative;
    width: 30%;
    height: 45%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 3px solid var(--color-dark-blue);
    outline: 2px solid white;
    padding: 2%;
}

.btn-exit {
    position: absolute;
    top: 20px;
    right: 25px;
    color: red;
    font-size: 1.75em;
    cursor: pointer;
    font-family: sans-serif
}

.top-text {
    color: var(--font-color-teal);
    margin: 0.5em 0;
}



</style>